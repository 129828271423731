<template>
  <ca-card class="mb-3">
    <template #header>
      <h4 class="mb-0">
        Produkt / Tarif
      </h4>
    </template>
    <template #body>
      <form
        novalidate
        @submit.prevent="save"
      >
        <ca-alert
          v-if="noRateFound"
          variant="danger"
          message="Es wurde kein Tarif für die eingegebenen Daten gefunden"
          @close="noRateFound = false"
        />
        <basic-select
          v-model="order.product.productId"
          data-test="kaufschutz-product-product"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="bitte wählen"
          :horizontal="true"
          label="Produkt"
          :options="productOptions"
          required
          @input="$set(order.product, 'rate', undefined)"
        />
        <BasicCurrencyInput
          v-model="order.car.price"
          data-test="kaufschutz-product-price"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          required
          placeholder="Kaufpreis"
          label="Kaufpreis"
        />
        <basic-select
          v-model="order.product.duration"
          data-test="kaufschutz-product-laufzeit"
          :disabled="!order.car.price"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="bitte wählen"
          :horizontal="true"
          label="Laufzeit"
          :options="laufzeitOptions"
          required
          @input="$set(order.product, 'rate', undefined)"
        />
        <basic-datepicker
          v-model="order.product.beginContractDate"
          data-test="kaufschutz-product-begin-contract-date"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Beginn"
          required
          placeholder="TT.MM.JJJJ"
        />
        <basic-select
          v-model="order.product.paymentRate"
          data-test="kaufschutz-product-payment-rate"
          :disabled="!order.product.rate"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="bitte wählen"
          :horizontal="true"
          label="Zahlweise"
          :options="paymentRateOptions"
          required
        />

        <div class="form-row mb-3">
          <div class="col-12 col-md-4">
            <span>Netto laut zahlweise</span>
          </div>
          <div class="col-12 col-md-8">
            <span class="font-weight-bold">
              <span v-if="order.product.rate && order.product.paymentRate">
                {{ rateNetto | formatPrice }}
              </span>
              <span v-else>
                -
              </span>
            </span>
          </div>
        </div>

        <div class="form-row mb-3">
          <div class="col-12 col-md-4">
            <span>Brutto laut zahlweise</span>
          </div>
          <div class="col-12 col-md-8">
            <span class="font-weight-bold">
              <span v-if="order.product.rate && order.product.paymentRate">
                {{ rateBrutto | formatPrice }}
              </span>
              <span v-else>
                -
              </span>
            </span>
          </div>
        </div>

        <div class="form-row mb-3">
          <div class="col-12 col-md-4">
            <span>Netto gesamt</span>
          </div>
          <div class="col-12 col-md-8">
            <span class="font-weight-bold">
              <span v-if="order.product.rate">
                {{ nettoTotal | formatPrice }}
              </span>
              <span v-else>
                -
              </span>
            </span>
          </div>
        </div>

        <div class="form-row mb-3">
          <div class="col-12 col-md-4">
            <span>Brutto gesamt</span>
          </div>
          <div class="col-12 col-md-8">
            <span class="font-weight-bold">
              <span v-if="order.product.rate">
                {{ bruttoTotal | formatPrice }}
              </span>
              <span v-else>
                -
              </span>
            </span>
          </div>
        </div>

        <div
          v-if="order.product.rate && order.product.paymentRate && rateZuschlag"
          class="form-row mb-3"
        >
          <div class="col-12 col-md-4">
            <span>Zuschlag</span>
          </div>
          <div class="col-12 col-md-8">
            <span class="font-weight-bold">
              <span v-if="order.product.rate && order.product.paymentRate">
                {{ rateZuschlag | formatPercent }}
              </span>
              <span v-else>
                -
              </span>
            </span>
          </div>
        </div>

        <basic-checkbox
          v-model="order.gba"
          data-test="kaufschutz-product-gba"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          name="GB-A/GB-B"
          :required="extend || !noRateFound && !order.car.noHsnTsn"
          :disabled="!(edit || extend || !noRateFound && !order.car.noHsnTsn)"
          label="Die Vertragsbedingungen werden vom Käufer/Halter gelesen und akzeptiert und werden vom Verkäufer/Händler ausgedruckt und dem Käufer/Halter ausgehändigt."
        />

        <basic-checkbox
          v-model="order.isHandedOut"
          data-test="kaufschutz-product-is-handed-out"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          name="Vertrag ausdruck"
          :required="extend || !noRateFound && !order.car.noHsnTsn"
          :disabled="!(edit || extend || !noRateFound && !order.car.noHsnTsn)"
          label="Der Vertag wird vom Verkäufer/Händler ausgedruckt und dem Käufer/Halter ausgehändigt."
        />
        <ca-alert
          v-if="order.product.rate && order.product.rate.extendRateIds && order.product.rate.extendRateIds.length != 0 && (!order.owner.email && !order.owner.mobile)"
          variant="danger"
          message="E-Mailadresse oder Mobiltelefonnummer werden zwingend zum Vertragsabschluss benötigt."
          not-closeable
        />
      </form>
    </template>
  </ca-card>
</template>

<script>
import BasicSelect from '@/components/BaseComponents/BasicSelect'
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox'
import BasicCurrencyInput from '@/components/BaseComponents/BasicCurrencyInput'
import CaCard from '@/components/Card'
import formatPrice from '../../../filters/formatPrice.js'
import formatPercent from '../../../filters/formatPercent'

export default {
  inject: ['$validator'],
  name: 'ProductForm',
  components: {
    BasicSelect,
    BasicDatepicker,
    BasicCheckbox,
    BasicCurrencyInput,
    CaCard
  },
  filters: {
    formatPrice,
    formatPercent
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    products: {
      type: Array,
      default: () => []
    },
    noRateFound: {
      type: Boolean,
      default: false
    },
    extend: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    laufzeitOptions: [
      { label: '12 Monate', value: 12 },
      { label: '24 Monate', value: 24 },
      { label: '36 Monate', value: 36 },
      { label: '48 Monate', value: 48 },
      { label: '60 Monate', value: 60 }
    ]
  }),
  computed: {
    paymentRateOptions () {
      const paymentRateOptions = [
        { label: 'Einmalig', value: 'ONCE' }
      ]

      if (this.order.product.duration !== 12) {
        paymentRateOptions.push(
          { label: 'Jährlich', value: 'YEARLY' }
        )
      }
      paymentRateOptions.push(
        { label: 'Monatlich', value: 'MONTHLY' }
      )
      return paymentRateOptions
    },
    productOptions () {
      return this.products.map(product => ({
        label: product.name,
        value: product._id
      }))
    },
    netto () {
      if (!this.order.product.rate) return null
      return this.brutto / 1.19
    },
    brutto () {
      if (!this.order.product.rate) return null
      return this.order.product.rate.percent * this.order.car.price
    },
    rateNetto () {
      return (this.netto / this.totalRates) * (1 + this.rateZuschlag)
    },
    rateBrutto () {
      return (this.brutto / this.totalRates) * (1 + this.rateZuschlag)
    },
    nettoTotal () {
      return this.netto * (1 + this.rateZuschlag)
    },
    bruttoTotal () {
      return this.brutto * (1 + this.rateZuschlag)
    },
    totalRates () {
      switch (this.order.product.paymentRate) {
      case 'ONCE': {
        return 1
      }
      case 'YEARLY': {
        return this.order.product.duration / 12
      }
      case 'MONTHLY': {
        return this.order.product.duration
      }
      default: {
        return 0
      }
      }
    },
    rateZuschlag () {
      if (!this.order.product.paymentRate || !this.order.product.rate.zuschlag) return 0
      return this.order.product.rate.zuschlag[this.order.product.paymentRate]
    },
    me () {
      return this.$store.getters['auth/user'].user
    }
  },
  watch: {
    rateNetto: {
      handler: 'updatePrice'
    }
  },
  created () {
    if (this.edit) {
      if (this.order.product && this.order.product.rate) {
        let productId
        if (this.order.product.rate.productId) {
          productId = this.order.product.rate.productId
        } else {
          const product = this.products.find(product => this.order.product.rate.product.hn === product.hn)
          productId = product._id
        }
        this.$set(this.order.product, 'productId', productId)
        this.selectedProductHn = this.order.product.rate.product.hn
        this.selectedLaufzeit = this.order.product.rate.laufzeit
      }
    }
  },
  methods: {
    updatePrice () {
      this.$set(this.order.product, 'rateNetto', this.rateNetto)
      this.$set(this.order.product, 'rateBrutto', this.rateBrutto)
      this.$set(this.order.product, 'gross', this.bruttoTotal)
      this.$set(this.order.product, 'net', this.nettoTotal)
    },
    reset () {
      this.selectedProductHn = undefined
      this.selectedLaufzeit = undefined

      this.$set(this.order.product, 'rate', undefined)
      this.$set(this.order.product, 'rateId', undefined)
    },
    getUserPriceModifier (selectedRate) {
      const productPriceModifier = this.me.products.find(product => product.productId === selectedRate.productId)
      return getPriceModifier(productPriceModifier)

      function getPriceModifier (priceModifierConf) {
        if (!priceModifierConf || !priceModifierConf.priceModifierValue) return
        let priceModifier
        switch (priceModifierConf.priceModifierUnit) {
        case 'PERCENT': {
          priceModifier = priceModifierConf.priceModifierValue / 100
          break
        }
        case 'EURO': {
          // get percent value for number for netto calculation
          priceModifier = priceModifierConf.priceModifierValue / selectedRate.brutto
          break
        }
        }
        return (priceModifierConf.priceModifierType === 'SURCHARGE' ? 1 : -1) * priceModifier
      }
    }
  }
}
</script>
