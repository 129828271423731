<template>
  <div>
    <order-modal
      v-if="savedOrder"
      :show="showOrderModal"
      :order="savedOrder"
      @close="closeModal"
    />

    <ca-header
      heading="Kaufschutz Antrag erstellen"
      :column-layout="columnLayout"
      show-column-layout
      :loading="loading"
      @columnLayout="columnLayout = $event"
    >
      <template #alert>
        <ca-alert
          v-if="!!saveErrorMessage"
          variant="danger"
          :message="saveErrorMessage"
          @close="saveErrorMessage = null"
        />
        <ca-alert
          v-if="showFormInvalidHint"
          variant="info"
          message="Es sind noch nicht alle erforderlichen Felder ausgefüllt"
          @close="showFormInvalidHint = null"
        />
      </template>
      <template #buttonSpace>
        <submit-buttons
          :order-creation-type="orderCreationType"
          :show-popover="!chassiIsAllowed"
          popover-placement="bottom"
          popover-parent-id="submit-top"
          @save="save"
        />
      </template>
    </ca-header>

    <div class="container-fluid px-4 pt-4">
      <div
        v-if="!loading"
        class="row"
      >
        <div
          class="col-12"
          :class="{'col-xl-8 col-xxl-6': !columnLayout}"
        >
          <div class="row">
            <div
              class="col-12"
              :class="{'col-lg-6': columnLayout}"
            >
              <owner-form
                class="mb-3"
                :order="order"
              />
              <miscellaneous-form
                class="mb-3"
                :order="order"
              />
            </div>
            <div
              class="col-12"
              :class="{'col-lg-6': columnLayout}"
            >
              <car-form
                class="mb-3"
                is-kaufschutz
                :car="order.car"
                :chassi-is-allowed="chassiIsAllowed"
              />
              <kaufschutz-product-form
                ref="productForm"
                class="mb-3"
                :order="order"
                :products="products"
                :no-rate-found="noRateFound"
              />
            </div>
          </div>
          <div
            v-if="!loading"
            class="form-row justify-content-end"
          >
            <submit-buttons
              :order-creation-type="orderCreationType"
              :show-popover="!chassiIsAllowed"
              popover-placement="top"
              popover-parent-id="submit-bottom"
              @save="save"
            />
          </div>
        </div>
        <dev-info :data="{order}" />
      </div>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import DevInfo from '@/components/DevInfo'
import OwnerForm from '@/components/Forms/Order/OwnerForm'
import CarForm from '@/components/Forms/Order/CarForm'
import MiscellaneousForm from '@/components/Forms/Order/MiscellaneousForm'
import KaufschutzProductForm from '@/components/Forms/Order/KaufschutzProductForm'
import SubmitButtons from '@/components/Forms/Order/SubmitButtons.vue'
import OrderModal from '@/components/Modals/OrderModal'
import debounce from 'lodash.debounce'
import feathers from '../../api'
export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  name: 'OrderCreate',
  components: {
    DevInfo,
    OwnerForm,
    CarForm,
    MiscellaneousForm,
    KaufschutzProductForm,
    SubmitButtons,
    OrderModal,
    CaHeader
  },
  data () {
    return {
      loading: false,
      error: null,
      columnLayout: false,
      showOrderModal: false,
      order: null,
      savedOrder: null,
      noRateFound: false,
      ratesPending: false,
      savePending: false,
      saveError: null,
      saveErrorMessage: null,
      showFormInvalidHint: false,
      products: [],
      chassiIsAllowed: true
    }
  },
  computed: {
    orderCreationType () {
      if (this.noRateFound || !this.chassiIsAllowed) {
        return 'request'
      } else {
        return 'contract'
      }
    },
    me () {
      return this.$store.getters['auth/user'].user
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    },
    'order.car.price': {
      handler: 'updateRates'
    },
    'order.car.monthFromRegistrationDate': {
      handler: 'updateRates'
    },
    'order.car.kw': {
      handler: 'updateRates'
    },
    'order.product.duration': {
      handler: 'updateRates'
    },
    'order.car.chassi': {
      handler: 'fetchChassisAllow'
    }
  },
  methods: {
    async save (orderType) {
      try {
        const valid = await this.$validator.validateAll()
        if (this.savePending) {
          return
        }
        if (!valid) {
          this.showFormInvalidHint = true
          return
        }
        this.savePending = true
        this.saveError = null
        this.saveErrorMessage = null
        this.showFormInvalidHint = false
        const isRequest = orderType === 'request'
        this.order.isContract = !isRequest
        this.order.isKaufschutz = true
        this.order.status = isRequest ? 0 : 1
        if (this.order.product.rate) {
          this.order.product.rateId = this.order.product.rate._id
        }
        this.savedOrder = this.order
        this.showOrderModal = true
        if (!this.order.mobility || !this.order.mobilityRate || isRequest) {
          this.savedOrder = await this.order.save()
        } else {
          this.savedOrder = await feathers.service('order-mobility').create(this.order)
        }
      } catch (error) {
        console.log(error)
        this.showOrderModal = false
        let msg = ''
        switch (error.message) {
        case 'Authentication token missing.': msg = 'Sie sind nicht mehr eingeloggt. Loggen Sie sich neu ein'; break
        case 'Das Erstellen von Policen/Anfragen ist für diesen Account gesperrt': msg = error.message; break
        default: msg = 'Der Server konnte die Anfrage nicht bearbeiten'
        }
        this.saveErrorMessage = msg
        this.saveError = error
      } finally {
        this.savePending = false
      }
    },
    async fetchData () {
      try {
        this.loading = true
        this.order = new this.$FeathersVuex.api.Order()
        if (this.$route.params.carId !== undefined) {
          this.order.car = await this.$store.dispatch('car/get', this.$route.params.carId)
        }
        this.products = await this.$store.dispatch('products/find', {
          query: {
            $client: { $own: true, paginate: false },
            isKaufschutz: true
          }
        })
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    },
    updateRates () {
      this.rates = []
      this.ratesPending = true
      this.debouncedFindRates()
    },
    debouncedFindRates: debounce(async function () {
      await this.findRates()
      this.ratesPending = false
    }, 100),
    async findRates () {
      const requiredValuesSet = [this.order.car.price, this.order.car.km, this.order.car.monthFromRegistrationDate, this.order.product.duration, this.order.product.productId]
        .every(value => value !== null && value !== undefined && value !== '')
      if (requiredValuesSet) {
        const rateRequest = await this.$store.dispatch('kaufschutz-rates/find', {
          query: {
            $client: {
              $own: true
            },
            $limit: 1,
            productId: this.order.product.productId,
            km: this.order.car.km,
            monthFromRegistrationDate: this.order.car.monthFromRegistrationDate,
            carPriceFrom: {
              $lte: this.order.car.price
            },
            carPriceTo: {
              $gte: this.order.car.price
            },
            laufzeit: this.order.product.duration
          }
        })
        const rate = rateRequest.data[0]
        if (rate) {
          this.$set(this.order.product, 'rate', rate)
          this.$set(this.order.product, 'rateId', rate._id)
          this.noRateFound = false
        } else {
          this.$delete(this.order.product, 'rate')
          this.$delete(this.order.product, 'rateId')
          this.noRateFound = true
        }
      }
    },
    async closeModal () {
      await this.$router.push({ name: 'OrderDetail', params: { orderId: this.savedOrder._id } })
      this.showOrderModal = false
    },
    saveToLocalstorage () {
      localStorage.setItem('orderData', JSON.stringify(this.order))
    },
    applyFromLocalstorage () {
      const orderData = JSON.parse(localStorage.getItem('orderData'))
      this.order.owner = orderData.owner
      this.order.car = orderData.car
    },
    async fetchChassisAllow () {
      if (this.order.car.chassi.length >= 17) {
        const query = {
          chassiNumber: this.order.car.chassi,
          isKaufschutz: true
        }
        try {
          this.chassiIsAllowed = await feathers.service('check-chassi-number').get(query)
        } catch (error) {
          this.error = error
          console.error(error)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

.order-info{
  position: sticky;
  top: 70px;
}

</style>
